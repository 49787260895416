import React from 'react'

const ProductCard = ({index,image,name}) => {
  return (
    <div key={index} className='border cursor-pointer  rounded-sm shadow-lg flex group flex-col items-center justify-center'>
      <img src={image} className='object-cover max-w-full group-hover:scale-105  transition-all ease-in-out delay-200 duration-500  h-[20vw] lg:w-[18vw] lg:h-[12vw] bg-cover'/>
      <div className='py-4 text-center'>{name}</div>
    </div>
  )
}

export default ProductCard
