import React from 'react'
import logo from '../assets/images/logo.png'
import { IoIosCall } from 'react-icons/io'
import { IoLocationSharp } from 'react-icons/io5'
import { CiMail } from 'react-icons/ci'

const Footer = () => {
    return (
        <div className='bg-slate-200   font-poppins '>
            <div className='flex flex-col md:flex-row items-start justify-between p-3'>
                <div>
                    <img src={logo} className='max-w-xs' />
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>Main Menu</h1>
                    <div className='flex flex-col gap-2  justify-start'>
                        <div>Home</div>
                        <div>About Us</div>
                        <div>Services</div>
                        <div>Contact Us</div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>Contact Us</h1>
                    <div className='flex flex-col gap-2  justify-start'>
                        <div className='flex items-center gap-2'>
                            <IoIosCall className='text-2xl  ' />
                            <p>090078601</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <IoLocationSharp className='text-2xl' />
                            <p>Ajman Free Zone Building C1, first floor.</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <CiMail className='text-2xl' />
                            <p className=' '>info@fortunemarineco.com</p>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <p className='pb-4 text-center'>All rights reserved FORTUNE MARINE</p>
        </div>
    )
}

export default Footer
