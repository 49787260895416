import React from 'react'
import TestimonialSlider from './TestimonialSlider'
//motion
import { motion } from 'framer-motion'
//variants'
import { fadeIn } from '../../variants';

const Testimonials = () => {
  return (
    <div className=' bg-slate-300 font-poppins py-32 text-center' id='testimonial'>
        <div className='container mx-auto h-full flex flex-col justify-center px-4 lg:px-24'>
            <h2
             className='text-4xl md:text-5xl lg:text-6xl font-medium text-primaryClr mb-8 xl:mb-0'>
                What client's <span className='text-primaryClr/70'>say .</span>
            </h2>
            <div
            >
                <TestimonialSlider/>
            </div>

        </div>
      
    </div>
  )
}

export default Testimonials
