import React from 'react'
import Connect from '../components/Contact/Connect'
import Contactus from '../components/Home/Contactus'
import HeroSection from '../components/About/HeroSection'
import WhyUs from '../components/Home/WhyUs'
import Vision from '../components/About/Vision'
import Process from '../components/About/Process'

const About = () => {
  return (
    <div>
        <HeroSection/>
        <Vision/>
        <Process/>
        <WhyUs/>
      <Connect/>
      <Contactus/>
    </div>
  )
}

export default About
