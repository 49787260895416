import React from 'react'
import ProductCard from './ProductCard'
import freshwater  from '../../assets/images/AuxMachinery/freshwatergenerator.jpg'
import oilywaterseperator  from '../../assets/images/AuxMachinery/oilywaterseperator.jpg'
import ballsatpump  from '../../assets/images/AuxMachinery/ballastpumps.jpg'
import gspump  from '../../assets/images/AuxMachinery/gspumps.jpg'
import coolers  from '../../assets/images/AuxMachinery/auxcoolers.jpg'
import firepumps  from '../../assets/images/AuxMachinery/firepumps.jpg'
import emergencyair  from '../../assets/images/AuxMachinery/emergencyaircompressor.jpg'
const AuxMachinery = () => {
        const EngineSpareParts=[
            {
                "name":"Fresh Water Generator",
                "image":freshwater
            },
            {
                "name":"Oily Water Seperators",
                "image":oilywaterseperator
            },
            {
                "name":"Ballast Pumps",
                "image":ballsatpump
            },
            {
                "name":"Gs Pumps",
                "image":gspump
            },
            {
                "name":"Coolers",
                "image":coolers
            },
            {
                "name":"Fire Pumps",
                "image":firepumps
            },
            {
                "name":"Emergency Air Compressor",
                "image":emergencyair
            },
           
        ]
      return (
        <div className='font-poppins'>
            
        {/* sub heading */}
        <div className='bg-gray-300 md:text-xl text-center py-6'>(All major and minor spares of <span className='font-medium'>MAN , b&w , sulzer, yanmar , mitsubishi, pilestick, daihatsu fiat , mirrlees , blackstone</span> etc)</div>
        {/* products */}
        <div className='bg-white flex items-center justify-center'>
        <div className='grid  grid-cols-2 xl:grid-cols-4 max-w-6xl gap-4 py-8' >
    
            {EngineSpareParts.map((product,index)=>{
                return(
                    <ProductCard
                    index={index}
                    name={product.name}
                    image={product.image}
                    />
                    
                )
            })}
            
        </div>
        </div>
      
    </div>
      )
    }
 


export default AuxMachinery
 