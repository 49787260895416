import React from 'react'

import MEngineSpares from '../components/Product/MEngineSpares'
import HeroSection from '../components/Product/HeroSection'
import {  useParams } from 'react-router-dom'
import AEngineSpare from '../components/Product/AEngineSpare'
import AuxMachinery from '../components/Product/AuxMachinery'
import DeckMachinery from '../components/Product/DeckMachinery'

const Products = () => {
  const {id} =useParams()
  return (
    <div className=' '>
      <HeroSection/>
      {id === 'engine-spare' && (<MEngineSpares/>)}
      {id === 'auxengine-spare' && (<AEngineSpare/>)}
      {id === 'aux-machinery' && (<AuxMachinery/>)}
      {id ==='dec-machinery' &&(<DeckMachinery/>)}
    
    </div>
  )
}

export default Products
