import React from 'react'
import { Swiper, SwiperSlide, SwiperSlider } from 'swiper/react'
import { FreeMode, Navigation, Pagination } from 'swiper'
//images
// import song2 from '../assets/images/song2.png'
// import diosend2 from '../assets/images/diosend2.png'
// import clusivix from '../assets/images/clusivix.png'

//react-icons
import { FaQuoteLeft } from 'react-icons/fa'
import {BiUserPin} from 'react-icons/bi'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// testimonial data
const testimonialData = [
    {
        image: '/t-avt-1.png',
        name: 'tracktechni',
        position: 'Customer',
        message:
            'Seller was very responsive and patient with revision requests',
    },
    {
        image: '/t-avt-2.png',
        name: 'anonomus0x',
        position: 'Customer',
        message:
            'very professional, eye for detail and concept. not just producing, but making an effort to make the project better',
    },
    {
        image: '/t-avt-3.png',
        name: 'valentyn101',
        position: 'Customer',
        message:
            'very professional, eye for detail and concept. not just producing, but making an effort to make the project better',
    },
    {
        image: '/t-avt-3.png',
        name: 'hangw888',
        position: 'Customer',
        message:
            'very professional, eye for detail and concept. not just producing, but making an effort to make the project better',
    },
    {
        image: '/t-avt-3.png',
        name: 'anonymous',
        position: 'Customer',
        message:
            'very professional, eye for detail and concept. not just producing, but making an effort to make the project better',
    },
    {
        image: '/t-avt-3.png',
        name: 'anonymous',
        position: 'Customer',
        message:
            'very professional, eye for detail and concept. not just producing, but making an effort to make the project better',
    },
];




const TestimonialSlider = () => {
    return (
        <Swiper
            // spaceBetween={10}
            spaceBetween={10}
            navigation={true}

            pagination={{
                clickable: true
            }}
            modules={[Navigation, Pagination]}
            className='h-[400px]'
        >
            {
                testimonialData.map((person, index) => {
                    return <SwiperSlide key={index}>
                        <div className='flex flex-col items-center md:flex-row gap-x-8 h-full px-16'>
                            <div className='w-full max-w-[300px] flex flex-col xl:justify-center items-center relative mx-auto xl:mx-0'>
                                <div className='flex flex-col justify-center items-center'>
                                    <div className='mb-2 mx-auto '>
                                        {/* <img src={person.image} width={100} height={100} alt='' /> */}
                                        <BiUserPin size={72} className='text-primaryClr'/>
                                    </div>
                                    <div className='text-lg'>{person.name}</div>
                                    <div className='text-[12px] uppercase font-extralight tracking-widest'>{person.position}</div>
                                </div>
                            </div>
                            <div className=' flex-1 flex-col
                             justify-center before:w-[1px] xl:before:bg-white/20
                             xl:before:absolute xl:before:left-0
                              xl:before:h-[200px] relative xl:pl-20 '>
                                <div className='mb-4'>
                                    <FaQuoteLeft className='text-4xl xl:text-6xl text-secondaryClr mx-auto md:mx-0' />
                                    <div>
                                        <div className='xl:text-lg text-center'>
                                            {person.message}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                })
            }

        </Swiper>
    )
}

export default TestimonialSlider



