import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/images/logo.png'

const NavX = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isProductDropdownOpen,setIsProdcutDropDownOpen]=useState(false)
    const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false)
    const dropdownRef = useRef(null);
    

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setIsSubDropdownOpen(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const mouseEnter =()=>{
        setIsDropdownOpen(true)
    }
const mouseLeave=()=>{
        setIsDropdownOpen(false)
    }

    const mouseEnterProduct =()=>{
        setIsProdcutDropDownOpen(true)
    }
const mouseLeaveProduct=()=>{
        setIsProdcutDropDownOpen(false)
    }
    return (
        <header className=' bg-slate-200  z-50 font-poppins w-full text-sm   '>
            <nav className=' w-full mx-auto px-6 md-1:flex md-1:items-center md-1:justify-between  border border-white/20  text-white backdrop-blur-[3px] py-1.5 shadow-lg rounded-xl  '>
                <div className='flex min-w-56 items-center justify-between '>
                    <NavLink
                        className='flex-none  rounded-md  text-xl font-semibold focus:outline-none focus:opacity-80'
                        to={'/'}
                    >
                        <img src={logo} className='w-60 '/>
                    </NavLink>
                    <div className='md-1:hidden'>
                        <button
                            type='button'
                            className='relative size-7 flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            aria-expanded={isMenuOpen}
                            aria-controls='hs-navbar-example'
                            aria-label='Toggle navigation'
                        >
                            <svg
                                className={`${isMenuOpen ? 'hidden' : 'block'} shrink-0 size-4`}
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='3' x2='21' y1='6' y2='6' />
                                <line x1='3' x2='21' y1='12' y2='12' />
                                <line x1='3' x2='21' y1='18' y2='18' />
                            </svg>
                            <svg
                                className={`${isMenuOpen ? 'block' : 'hidden'} shrink-0 size-4`}
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M18 6 6 18' />
                                <path d='m6 6 12 12' />
                            </svg>
                            <span className='sr-only'>Toggle navigation</span>
                        </button>
                    </div>
                </div>
                <div
                    id='hs-navbar-example'
                    className={`${
                        isMenuOpen
                            ? 'max-h-screen opacity-100'
                            : 'max-h-0 opacity-0'
                    }  transition-all ease-in-out duration-300   md-1:max-h-none md-1:opacity-100 `}
                >
                    <div className='flex flex-col  mt-5 md-1:flex-row md-1:items-center md-1:justify-between md-1:mt-0 '>
                        <NavLink
                        to={'/'}
                            className='font-medium text-base text-slate-600 hover:text-slate-800 focus:text-primaryClr  sm  md:px-2 md-1:px-6 focus:outline-none underline-hover'
                           
                           
                        >
                           Home
                        </NavLink>
                        
                        <NavLink
                        to={'/gallery'}
                            className='font-medium text-base text-slate-600 hover:text-slate-800 focus:text-primaryClr  sm  md:px-2 md-1:px-6 focus:outline-none underline-hover'
                           
                           
                        >
                           Gallery
                        </NavLink>
                        
                        
                        <div className='relative hidden md-1:flex  md:px-2 md-1:px-6'
                         onMouseEnter={mouseEnter}
                         onMouseLeave={mouseLeave}>
                            <button
                                id='hs-navbar-example-dropdown'
                                type='button'
                                className='flex items-center w-full  focus:outline-none text-base text-slate-600 hover:text-slate-800 focus:text-primaryClr  font-medium'
                                onClick={() =>
                                    setIsDropdownOpen(!isDropdownOpen)
                                }
                                aria-haspopup='menu'
                                aria-expanded={isDropdownOpen}
                                aria-label='Mega Menu'
                            >
                                Services
                                <svg
                                    className={`transition-transform duration-300 ms-1 shrink-0 size-4 ${isDropdownOpen ? '-rotate-180' : ''}`}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='m6 9 6 6 6-6' />
                                </svg>
                            </button>

                           

                            <div
                                className={`transition-opacity w-72 z-50 duration-150 ease-in-out absolute   font-medium bg-slate-400/80  shadow-md hidden md-1:block rounded-lg p-1 space-y-1 mt-6  ${
                                    isDropdownOpen
                                        ? 'opacity-100'
                                        : 'opacity-0 pointer-events-none'
                                }`}
                                role='menu'
                                aria-orientation='vertical'
                                aria-labelledby='hs-navbar-example-dropdown'
                            >
                                <NavLink
                                to={'/services/evaluation'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    href='#'
                                >
                                    Project Evaluation and Market Studies
                                </NavLink>
                                <div className='relative'>
                                   
                                </div>
                                <NavLink to={'/services/development'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                    Business Case Development
                                </NavLink>
                                <NavLink to={'/services/risk'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                   Market Risk and Sensitivity Studies
                                </NavLink>
                                <NavLink to={'/services/strategic'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                  Strategic Market Positioning
                                </NavLink>
                               
                                <NavLink to={'/services/intelligence'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                   Competitor Intelligence and Benchmarking
                                </NavLink>
                               
                                
                                <NavLink to={'/services/port'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                    Port Planning and Feasibility Studies
                                </NavLink>
                                <a
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    href='#'
                                >
                                    Team Account
                                </a>
                            </div>
                        </div>
                        <div className='relative flex md-1:hidden py-2 md:px-2 md-1:px-6'
                        >
                            <button
                                id='hs-navbar-example-dropdown'
                                type='button'
                                className='flex items-center w-full text-slate-600 hover:text-slate-800 focus:text-primaryClr text-base  focus:outline-none font-medium'
                                onClick={() =>
                                    setIsDropdownOpen(!isDropdownOpen)
                                }
                                aria-haspopup='menu'
                                aria-expanded={isDropdownOpen}
                                aria-label='Mega Menu'
                            >
                                Services
                                <svg
                                    className={`transition-transform duration-300 ms-1 shrink-0 size-4 ${isDropdownOpen ? '-rotate-180' : ''}`}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='m6 9 6 6 6-6' />
                                </svg>
                            </button>

                            <div
                                className={`transition-all text-primaryClr duration-300 ease-in-out md-1:hidden flex flex-col gap-5 pt-5 pl-6 ${
                                    isDropdownOpen
                                        ? 'opacity-100 max-h-screen'
                                        : 'opacity-0 max-h-0'
                                } overflow-hidden`}
                            >
                                <Link  to={'/services/evaluation'}
                                >
                                    <span> Project Evaluation and Market Studies</span>
                                </Link>
                                <Link to={'/services/development'}>
                                    <span>Business Case Development</span>
                                </Link>
                                <Link to={'/services/risk'}>
                                    <span> Market Risk and Sensitivity Studies</span>
                                </Link>
                                <Link to={'/services/strategic'}>
                                    <span> Strategic Market Positioning</span>
                                </Link>
                                <Link to={'/services/intelligence'}>
                                    <span> Competitor Intelligence and Benchmarking</span>
                                </Link>
                                <Link to={'/services/port'}>
                                    <span>  Port Planning and Feasibility Studies</span>
                                </Link>
                            </div>

                           
                        </div>

                        <div className='relative hidden md-1:flex  md:px-2 md-1:px-6'
                         onMouseEnter={mouseEnterProduct}
                         onMouseLeave={mouseLeaveProduct}>
                            <button
                                id='hs-navbar-example-dropdown'
                                type='button'
                                className='flex items-center w-full  focus:outline-none text-base text-slate-600 hover:text-slate-800 focus:text-primaryClr  font-medium'
                                onClick={() =>
                                    setIsProdcutDropDownOpen(!isProductDropdownOpen)
                                }
                                aria-haspopup='menu'
                                aria-expanded={isProductDropdownOpen}
                                aria-label='Mega Menu'
                            >
                                Product
                                <svg
                                    className={`transition-transform duration-300 ms-1 shrink-0 size-4 ${isProductDropdownOpen ? '-rotate-180' : ''}`}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='m6 9 6 6 6-6' />
                                </svg>
                            </button>

                           

                            <div
                                className={`transition-opacity w-72 z-50 duration-150 ease-in-out absolute   font-medium bg-slate-400/80  shadow-md hidden md-1:block rounded-lg p-1 space-y-1 mt-6  ${
                                    isProductDropdownOpen
                                        ? 'opacity-100'
                                        : 'opacity-0 pointer-events-none'
                                }`}
                                role='menu'
                                aria-orientation='vertical'
                                aria-labelledby='hs-navbar-example-dropdown'
                            >
                                <NavLink
                                to={'/products/engine-spare'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                   Main Engine Spares
                                </NavLink>
                                <div className='relative'>
                                   
                                </div>
                                <NavLink to={'/products/auxengine-spare'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                    Aux Machinery In Engine Room
                                </NavLink>
                                <NavLink to={'/products/aux-machinery'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                  Aux Machinery in Engine Room
                                </NavLink>
                                <NavLink to={'/products/dec-machinery'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                Aux Machinery in deck and Deck Machinery
                                </NavLink>
                               
                                {/* <NavLink to={'/products/dec-machinery'}
                                    className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm  hover:hover:bg-primaryClr/80 focus:outline-none focus:bg-primaryClr/80'
                                    
                                >
                                  Navigational Equipment
                                </NavLink> */}
                               
                                
                               
                               
                            </div>
                        </div>
                        <div className='relative flex md-1:hidden py-2 md:px-2 md-1:px-6'
                        >
                            <button
                                id='hs-navbar-example-dropdown'
                                type='button'
                                className='flex items-center w-full text-slate-600 hover:text-slate-800 focus:text-primaryClr text-base  focus:outline-none font-medium'
                                onClick={() =>
                                    setIsProdcutDropDownOpen(!isProductDropdownOpen)
                                }
                                aria-haspopup='menu'
                                aria-expanded={isProductDropdownOpen}
                                aria-label='Mega Menu'
                            >
                                Products
                                <svg
                                    className={`transition-transform duration-300 ms-1 shrink-0 size-4 ${isDropdownOpen ? '-rotate-180' : ''}`}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='m6 9 6 6 6-6' />
                                </svg>
                            </button>

                            <div
                                className={`transition-all text-primaryClr duration-300 ease-in-out md-1:hidden flex flex-col gap-5 pt-5 pl-6 ${
                                    isProductDropdownOpen
                                        ? 'opacity-100 max-h-screen'
                                        : 'opacity-0 max-h-0'
                                } overflow-hidden`}
                            >
                                <Link  to={'/services/evaluation'}
                                >
                                    <span> Project Evaluation and Market Studies</span>
                                </Link>
                                <Link to={'/services/development'}>
                                    <span>Business Case Development</span>
                                </Link>
                                <Link to={'/services/risk'}>
                                    <span> Market Risk and Sensitivity Studies</span>
                                </Link>
                                <Link to={'/services/strategic'}>
                                    <span> Strategic Market Positioning</span>
                                </Link>
                                <Link to={'/services/intelligence'}>
                                    <span> Competitor Intelligence and Benchmarking</span>
                                </Link>
                                <Link to={'/services/port'}>
                                    <span>  Port Planning and Feasibility Studies</span>
                                </Link>
                            </div>

                           
                        </div>
                        <NavLink
                            className='font-medium md:px-2 md-1:px-6 text-slate-600 hover:text-slate-800 focus:text-primaryClr  text-base focus:outline-none  underline-hover'
                            to={'/contact-us'}
                        >
                            Contact Us
                        </NavLink>
                        <NavLink
                            className='font-medium md:px-2 md-1:px-6 text-slate-600 hover:text-slate-800 focus:text-primaryClr  text-base focus:outline-none  underline-hover'
                            to={'/about-us'}
                        >
                            About Us
                        </NavLink>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default NavX
