import React from 'react'

const HeroSection = () => {
  return (
    <div className='h-[60vh] font-poppins bg-aboutbg bg-center bg-cover '>
        <div className='flex flex-col h-full items-center justify-center bg-black/50'>
        
            <h1 className=' text-4xl  font-semibold text-white drop-shadow-xl  md:text-5xl xl:text-7xl'>About Us</h1>
            <p className='max-w-6xl text-white text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium est nec sapien tempus, sed placerat ex suscipit. Etiam convallis ac erat at consequat. Vivamus eu pretium nunc. Donec ut magna id tellus consequat aliquam non in lacus. Aenean maximus commodo blandit. Phasellus viverra tincidunt felis ut molestie. </p>
     
        </div>
      
    </div>
  )
}

export default HeroSection
