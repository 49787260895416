import React from 'react'
import seviceLogo from '../../assets/images/Services/service.webp'
import { useParams } from 'react-router-dom'

const ServiceInfo = () => {
    const { id } = useParams()

    return (
        <div className='font-poppins bg-slate-200 '>
            {id === 'evaluation' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4   pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Project Evaluation and Market Studies
                        </h1>
                        <ul className='flex px-4 md:px-0 md:pl-8 flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg list-disc'>
                            <li>
                                Leveraging off our unrivalled industry expertise
                                and proprietary tools, MSI is uniquely
                                positioned to advise clients on projects and
                                markets across all sectors of the maritime
                                spectrum.
                            </li>

                            <li>
                                We pursue an integrated and consistent approach
                                to problem-solving, incorporating a range of
                                economic, financial and technical factors into
                                bespoke solutions for our clients.
                            </li>

                            <li>
                                We advise our clients on the viability and
                                expected financial returns of specific projects
                                and investments, identifying the timing and
                                magnitude of recommended investments and
                                divestments.
                            </li>

                            <li>
                                Results are tested under different scenarios to
                                identify profit maximisation strategies and key
                                market risks.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'development' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo ' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Business Case Development
                        </h1>
                        <p className='text-sm md:text-lg pt-8'>
                            MSI regularly assists its clients in developing
                            business cases for the purpose of securing
                            management commitment and approval for investment in
                            a project. The typical MSI business case defines:
                        </p>
                        <ul className='flex px-4 md:px-0 md:pl-8 flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg list-disc'>
                            <li>
                                the opportunity, expected outcome and alignment
                                with corporate strategy
                            </li>

                            <li>
                                an evaluation of different investment options
                                and the recommended approach
                            </li>

                            <li>critical success factors;</li>

                            <li>
                                expected benefits and financial projections
                                measured against internal targets (NPV, IRR,
                                ROCE)
                            </li>
                            <li>
                                identification of risks and mitigation
                                strategies.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'risk' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Market Risk and Sensitivity Studies
                        </h1>
                        <p className='text-sm md:text-lg pt-8'>
                            Leveraging off our unrivalled industry expertise and
                            proprietary tools, MSI regularly works closely with
                            its clients to:
                        </p>
                        <ul className=' flex px-4 md:px-0 md:pl-8 flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg list-disc'>
                            <li>identify and quantify market risk.</li>

                            <li>
                                quantify the sensitivity of investment
                                alternatives to changes in a range of different
                                variables.
                            </li>

                            <li>develop strategies to mitigate these.</li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'strategic' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Strategic Market Positioning
                        </h1>
                        <ul className=' flex px-4 md:px-0  flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg '>
                            <li>
                                Leveraging off our unrivalled industry expertise
                                and proprietary tools, MSI regularly dissects
                                markets into sectors, segments and sub-segments
                                by asset class, specification and deployment to
                                assess prospects for growth and profitability.
                            </li>

                            <li>
                                Working closely with our clients, MSI can
                                identify and recommend sectors, segments and
                                assets for prioritised investment, and those
                                where future growth and profitability are at
                                risk.
                            </li>

                            <li>
                                MSI regularly works with clients to develop
                                bespoke client-speciﬁc sales and planning tools
                                designed to assess the relative attractiveness
                                of diﬀerent shipping sectors and segments from a
                                near-term perspective based on a combination of
                                weighted 'external' market and 'internal'
                                client-speciﬁc parameters.
                            </li>
                        </ul>
                    </div>
                </div>
            )}

            {id === 'intelligence' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Competitor Intelligence and Benchmarking
                        </h1>
                        <ul className=' flex px-4 md:pl-8 md:px-0  flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg list-disc '>
                            <li>
                                insight into competitor business – operated
                                fleet and orderbook, regional hubs, key clients,
                                partnerships.
                            </li>

                            <li>
                                fleet deployment and benchmarking – trading
                                patterns, operating speeds, port times, etc.
                            </li>

                            <li>market share analysis.</li>
                            <li>
                                newbuilding, S&P and chartering performance –
                                benchmarking against the wider market and
                                against market indices.
                            </li>
                            <li>
                                benchmarking operating cost performance against
                                peers.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'port' && (
                <div className='flex flex-col md:flex-row items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20'>
                    <img src={seviceLogo} alt='service-logo' />
                    <div className='flex flex-col py-8 md:py-20 items-center md:items-start'>
                        <h1 className='text-3xl text-center md:text-start md:text-4xl font-bold text-slate-600'>
                            Port Planning and Feasibility Studies
                        </h1>
                        <ul className=' flex px-4  md:px-0  flex-col gap-4  max-w-2xl pt-4 text-sm md:text-lg  '>
                            <li>
                                As maritime trade specialists, MSI understands
                                the value that thorough demand and traffic
                                forecasting can bring to port projects – the
                                simple linear trend forecasts that some
                                consultants use can be misleading and ultimately
                                financially costly.
                            </li>

                            <li>
                                Working alone or in partnership with port
                                engineering consultants, MSI can support clients
                                with port planning and feasibility studies that
                                draw upon MSI’s unrivalled industry expertise
                                and proprietary tools. MSI can provide demand
                                and traffic forecasting broken down by sector,
                                segment and sub-segment, capturing future shifts
                                in trade patterns and vessel size configurations
                                to ensure optimal planning of port facilities.{' '}
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'provision' && (
                <div className='flex  items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20 '>
                    <div className='flex flex-col gap-4 items-start justify-center max-w-4xl'>
                        <h1 className='text-5xl font-bold mb-6 text-primaryClr text-center w-full'>
                            Provisions & Slop Chest
                        </h1>
                        <h2 className='text-3xl   font-medium text-primaryClr'>
                            Premier Provision Store Suppliers in UAE: Unmatched
                            Quality and Timely Delivery
                        </h2>
                        <p className='text-lg  '>
                            At Fortune Marine, we set the standard for
                            excellence in shipchandling. As a trusted partner in
                            the maritime industry, we supply a comprehensive
                            range of fresh, dry, and frozen provisions to
                            vessels both offshore and onshore.
                        </p>
                        <h2 className='text-3xl   font-semibold text-primaryClr'>
                            Our Provisions & Slop Chest Services
                        </h2>
                        <p className='text-lg'>
                            At Al Baraka, we pride ourselves on offering an
                            extensive range of high-quality provisions,
                            including:
                        </p>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Fresh Provisions
                                </span>
                                : Fruits, vegetables, dairy products, and meats.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Dry Provisions
                                </span>
                                : Grains, cereals, canned goods, and snacks.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Frozen Provisions
                                </span>
                                : Frozen meats, seafood, and ready-to-eat meals.
                            </li>
                        </ul>
                        <h2 className='text-3xl  text-center font-semibold text-primaryClr'>
                            Commitment to Quality and Timely Delivery
                        </h2>
                        <p className='text-lg'>
                            Our certified team, with unparalleled experience,
                            ensures that all products are delivered safely and
                            securely, adhering to the highest standards of
                            quality. We understand the importance of timely
                            delivery in maintaining the efficiency of maritime
                            operations, and our logistics are designed to meet
                            these needs seamlessly.
                        </p>

                        <h2 className='text-3xl  text-center font-semibold text-primaryClr'>
                            {' '}
                            Why We Stand Out
                        </h2>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Expertise
                                </span>
                                : Our dedicated team’s expertise and commitment
                                to accountability, discipline, and teamwork make
                                us stand out in the industry.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Customer Focus
                                </span>
                                : We place our clients at the heart of
                                everything we do, ensuring their needs are met
                                with precision and care.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Support
                                </span>
                                : Beyond provisioning, we offer comprehensive
                                after-sales support, handling logistics and
                                materials efficiently to save you time and
                                resources.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'maintenance' && (
                <div className='flex  items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20 '>
                    <div className='flex flex-col gap-4 items-start justify-center max-w-4xl'>
                        <h2 className='text-5xl font-bold mb-6 text-primaryClr text-center w-full'>
                            Maintenance and Repair
                        </h2>
                        <h2 className='text-3xl font-medium text-primaryClr'>
                            Comprehensive Maintenance and Repair Services
                        </h2>
                        <p className='text-lg'>
                            At Fortune Marine, we understand the critical
                            importance of maintaining your vessel in peak
                            condition. Our Maintenance and Repair services are
                            designed to ensure your ship remains operational and
                            efficient, reducing downtime and extending the life
                            of your vessel.
                        </p>
                        <h2 className='text-3xl font-semibold text-primaryClr'>
                            Our Maintenance and Repair Services
                        </h2>
                        <p className='text-lg'>
                            We offer a wide array of maintenance and repair
                            solutions, including:
                        </p>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Cleaning Equipment and Materials
                                </span>{' '}
                                We provide a comprehensive range of cleaning
                                tools and materials essential for maintaining
                                the cleanliness and hygiene of your vessel. From
                                brooms to specialized cleaning agents, we have
                                everything you need to keep your ship in
                                pristine condition.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Paints, Solvents, and Lubricants
                                </span>{' '}
                                Our high-quality paints, solvents, and
                                lubricants are specifically formulated for
                                maritime use. Whether it's for regular upkeep or
                                extensive repairs, our products ensure your
                                vessel’s surfaces and machinery are
                                well-protected and operating smoothly.
                            </li>
                        </ul>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Quality and Reliability
                        </h2>
                        <p className='text-lg'>
                            Our maintenance products are sourced from leading
                            manufacturers, ensuring they meet the highest
                            standards of quality and reliability. We understand
                            that maritime conditions can be harsh, and our
                            supplies are chosen for their durability and
                            effectiveness.
                        </p>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Why Choose Fortune Marine for Maintenance and Repair
                        </h2>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Unmatched Expertise
                                </span>
                                : Our team possesses deep knowledge and
                                experience in maritime maintenance, providing
                                you with the best solutions tailored to your
                                specific needs.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Customer-Centric Approach
                                </span>
                                : We prioritize your needs, offering
                                personalized service to ensure your complete
                                satisfaction. Our goal is to support you with
                                the right products and timely delivery,
                                enhancing your vessel's performance and
                                longevity.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Comprehensive Support
                                </span>
                                : Beyond providing top-notch products, we offer
                                extensive after-sales support. Our team is
                                always ready to assist with logistics, ensuring
                                you receive your supplies promptly and
                                efficiently.
                            </li>
                        </ul>
                    </div>
                </div>
            )}

            {id === 'safety' && (
                <div className='flex  items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20 '>
                    <div className='flex flex-col gap-4 items-start justify-center max-w-4xl'>
                        <h2 className='text-5xl font-bold mb-6 text-primaryClr text-center w-full'>
                            Safety Equipment
                        </h2>
                        <h2 className='text-3xl font-medium text-primaryClr'>
                            Essential Safety Gear and Emergency Equipment
                        </h2>
                        <p className='text-lg'>
                            At Fortune Marine, the safety of your crew and
                            vessel is our top priority. Our Safety Equipment
                            services offer a comprehensive range of critical
                            safety gear and emergency equipment designed to
                            ensure maritime safety and compliance with
                            regulations.
                        </p>
                        <h2 className='text-3xl font-semibold text-primaryClr'>
                            Our Safety Equipment Services
                        </h2>
                        <p className='text-lg'>
                            We provide a wide selection of essential safety
                            equipment, including:
                        </p>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Life Vests, Lifeboats, and Safety Gear
                                </span>{' '}
                                Essential life-saving gear for maritime safety.
                                Our life vests, lifeboats, and other safety
                                equipment are designed to meet international
                                safety standards, ensuring the protection of
                                your crew in emergency situations.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Fire Extinguishers and Emergency Equipment
                                </span>{' '}
                                Fire prevention and emergency response tools. We
                                offer high-quality fire extinguishers and
                                emergency equipment to address potential fire
                                hazards and respond effectively to emergencies,
                                safeguarding your vessel and crew.
                            </li>
                        </ul>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Commitment to Safety and Compliance
                        </h2>
                        <p className='text-lg'>
                            Our safety equipment is sourced from trusted
                            manufacturers and complies with the latest maritime
                            safety regulations. We are dedicated to providing
                            you with reliable, high-quality products that ensure
                            the highest level of safety and preparedness.
                        </p>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Why Choose Fortune Marine for Safety Equipment
                        </h2>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Top-Quality Gear
                                </span>
                                : We supply safety equipment that meets rigorous
                                quality standards, providing you with reliable
                                and effective solutions for crew and vessel
                                safety.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Compliance and Certification
                                </span>
                                : Our equipment adheres to international safety
                                regulations and certifications, ensuring you
                                meet legal requirements and industry standards.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Expert Support
                                </span>
                                : Our team is dedicated to helping you select
                                the right safety gear and ensuring timely
                                delivery. We offer expert advice and support to
                                meet your specific safety needs.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'technical' && (
                <div className='flex  items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20 '>
                    <div className='flex flex-col gap-4 items-start justify-center max-w-4xl'>
                        <h2 className='text-5xl font-bold mb-6 text-primaryClr text-center w-full'>
                            Deck and Engine Tools
                        </h2>
                        <h2 className='text-3xl font-medium text-primaryClr'>
                            Specialized Tools for Deck and Engine Maintenance
                        </h2>
                        <p className='text-lg'>
                            At Fortune Marine, we understand the critical role
                            that well-maintained tools play in vessel
                            operations. Our Deck and Engine Tools service
                            provides specialized equipment designed to keep your
                            vessel’s deck and engine systems in optimal
                            condition.
                        </p>
                        <h2 className='text-3xl font-semibold text-primaryClr'>
                            Our Deck and Engine Tools Services
                        </h2>
                        <p className='text-lg'>
                            We offer a comprehensive range of tools and supplies
                            for deck and engine maintenance, including:
                        </p>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Deck and Engine Tools
                                </span>{' '}
                                : Specialized tools for deck and engine
                                maintenance. Our selection includes high-quality
                                wrenches, screwdrivers, and other essential
                                tools designed to handle the rigorous demands of
                                maritime environments, ensuring effective
                                maintenance and repair.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Electrical Components and Spare Parts
                                </span>{' '}
                                : Electrical supplies and spare parts for vessel
                                systems. We provide a wide range of electrical
                                components and spare parts, including wiring,
                                circuit breakers, and fuses, to keep your
                                vessel’s electrical systems running smoothly and
                                efficiently.
                            </li>
                        </ul>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Quality and Durability
                        </h2>
                        <p className='text-lg'>
                            Our tools and electrical components are sourced from
                            reputable manufacturers, ensuring high quality and
                            durability. Designed to withstand the harsh maritime
                            environment, our products provide reliable
                            performance and longevity.
                        </p>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Why Choose Fortune Marine for Deck and Engine Tools
                        </h2>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Specialized Equipment
                                </span>
                                : We offer tools and components tailored for
                                deck and engine maintenance, meeting the
                                specific needs of maritime operations.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Reliable Supply
                                </span>
                                : Our extensive inventory ensures you have
                                access to the right tools and parts when you
                                need them, reducing downtime and enhancing
                                operational efficiency.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Expert Guidance
                                </span>
                                : Our knowledgeable team is here to assist you
                                in selecting the best tools and components for
                                your vessel, providing support and advice to
                                meet your maintenance needs.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {id === 'lubricants' && (
                <div className='flex  items-center justify-evenly px-4 pt-8 md:pt-0 lg:px-20 lg:py-20 '>
                    <div className='flex flex-col gap-4 items-start justify-center max-w-4xl'>
                        <h2 className='text-5xl font-bold mb-6 text-primaryClr text-center w-full'>
                            Fuel Oil and Lubricants Supply
                        </h2>
                        <h2 className='text-3xl font-medium text-primaryClr'>
                            High-Quality Fuel Oil and Lubricants for Efficient
                            Vessel Operations
                        </h2>
                        <p className='text-lg'>
                            At Fortune Marine, we provide top-tier fuel oil and
                            lubricants essential for maintaining the efficiency
                            and performance of your vessel. Our Fuel Oil and
                            Lubricants Supply service is designed to meet the
                            demanding needs of maritime operations with
                            high-quality products.
                        </p>
                        <h2 className='text-3xl font-semibold text-primaryClr'>
                            Our Fuel Oil and Lubricants Services
                        </h2>
                        <p className='text-lg'>
                            We offer a comprehensive range of fuel oils and
                            lubricants, including:
                        </p>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Fuel Oil
                                </span>{' '}
                                : High-quality fuel oil for efficient vessel
                                operations. Our fuel oil meets stringent
                                standards to ensure optimal performance,
                                combustion efficiency, and reduced emissions,
                                supporting smooth and reliable vessel operation.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Lubricants
                                </span>{' '}
                               : Superior lubricants for engine and machinery
                                maintenance. Our lubricants are designed to
                                reduce friction, wear, and tear on engine
                                components, extending the lifespan of your
                                machinery and ensuring smooth operation.
                            </li>
                        </ul>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Commitment to Quality and Efficiency
                        </h2>
                        <p className='text-lg'>
                            Our fuel oils and lubricants are sourced from
                            reputable suppliers and meet the highest industry
                            standards. We are committed to providing products
                            that enhance vessel performance and ensure
                            compliance with environmental regulations.
                        </p>
                        <h2 className='text-3xl text-center font-semibold text-primaryClr'>
                            Why Choose Fortune Marine for Fuel Oil and
                            Lubricants
                        </h2>
                        <ul className='list-disc text-lg'>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Premium Quality
                                </span>
                                : We supply only high-quality fuel oils and
                                lubricants that meet rigorous performance and
                                safety standards.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Efficient Supply
                                </span>
                                : Our streamlined logistics ensure timely and
                                reliable delivery of fuel and lubricants,
                                minimizing downtime and supporting efficient
                                vessel operations.
                            </li>
                            <li>
                                <span className='font-bold text-primaryClr'>
                                    Expert Advice
                                </span>
                                : Our experienced team provides expert guidance
                                on selecting the right products for your
                                specific needs, ensuring optimal performance and
                                maintenance.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServiceInfo
