import React from 'react'
import img1 from '../../assets/images/Services/provision.png'
import img2 from '../../assets/images/Services/screwdriver.png'
import img3 from '../../assets/images/Services/vest.png'
import img4 from '../../assets/images/Services/supplies.png'
import img5 from '../../assets/images/Services/barrel.png'
import { LuArrowRightCircle } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2'

const Services = () => {
    return (
        <div className=' bg-slate-200 font-poppins '>
            <div className='flex flex-col px-4 md:px-20  py-24'>
                <div className='text-primaryClr text-3xl md:text-5xl lg:text-7xl font-semibold'>
                    Services .
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 pt-12'>
                    <div className='flex flex-col gap-2 p-8 hover:scale-95 cursor-pointer transition-all ease-in-out duration-500 border-2 rounded-md  border-slate-600/10 shadow-lg '>
                        <img src={img1} className='w-20' />
                        <p className='uppercase text-primaryClr font-semibold  text-xl '>
                            PROVISION
                        </p>
                        <p className='text-sm  tracking-tight  '>
                            FORTUNE MARINE is a reliable ship chandler partner
                            supplying all ranges of fresh, dry and frozen
                            provisions to ships, offshore or onshore
                        </p>
                        <div className='h-full w-full flex justify-end items-end'>
                            <Link to={'/services/provision'}>
                                <LuArrowRightCircle className='text-3xl text-primaryClr' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 p-8 hover:scale-95 cursor-pointer transition-all ease-in-out duration-500 border-2 rounded-md  border-slate-600/10 shadow-lg '>
                        <img src={img2} className='w-20' />
                        <p className='uppercase text-primaryClr font-semibold  text-xl '>
                            Maintenance and Repair
                        </p>
                        <p className='text-sm  tracking-tight  '>
                            Essential supplies and equipment for maintaining and
                            repairing maritime vessels.
                        </p>
                        <div className='h-full w-full flex justify-end items-end'>
                            <Link to={'/services/maintenance'}>
                                <LuArrowRightCircle className='text-3xl text-primaryClr' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 p-8 hover:scale-95 cursor-pointer transition-all ease-in-out duration-500 border-2 rounded-md  border-slate-600/10 shadow-lg '>
                        <img src={img3} className='w-20' />
                        <p className='uppercase text-primaryClr font-semibold  text-xl '>
                        Safety Equipment
                        </p>
                        <p className='text-sm  tracking-tight  '>
                        Critical safety gear and emergency equipment for crew and vessel safety.
                        </p>
                        <div className='h-full w-full flex justify-end items-end'>
                            <Link to={'/services/safety'}>
                                <LuArrowRightCircle className='text-3xl text-primaryClr' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 p-8 hover:scale-95 cursor-pointer transition-all ease-in-out duration-500 border-2 rounded-md  border-slate-600/10 shadow-lg '>
                        <img src={img4} className='w-20' />
                        <p className='uppercase text-primaryClr font-semibold  text-xl '>
                        Technical Supplies
                        </p>
                        <p className='text-sm  tracking-tight  '>
                        Tools and spare parts necessary for the technical operations of vessels.
                        </p>
                        <div className='h-full w-full flex justify-end items-end'>
                            <Link to={'/services/technical'}>
                                <LuArrowRightCircle className='text-3xl text-primaryClr' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 p-8 hover:scale-95 cursor-pointer transition-all ease-in-out duration-500 border-2 rounded-md  border-slate-600/10 shadow-lg '>
                        <img src={img5} className='w-20' />
                        <p className='uppercase text-primaryClr font-semibold  text-xl '>
                        Bunkering Services
                        </p>
                        <p className='text-sm  tracking-tight  '>
                        Reliable supply of fuel oil and lubricants for maritime vessels.
                        </p>
                        <div className='h-full w-full flex justify-end items-end'>
                            <Link to={'/services/lubricants'}>
                                <LuArrowRightCircle className='text-3xl text-primaryClr' />
                            </Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Services
