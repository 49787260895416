import React from 'react'
import visionImg from '../../assets/images/AboutUS/aboutus1.webp'

const Vision = () => {
  return (
    <div className='bg-slate-200'>
        <div className='flex flex-col md:flex-row items-center justify-between px-4 md:px-20 py-20'>
          
          <div className=' flex flex-col md:w-1/2 gap-4'>
            <h1 className='font-bold text-3xl text-center md:text-start text-primaryClr md:text-5xl'>Our Vision</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium est nec sapien tempus, sed placerat ex suscipit. Etiam convallis ac erat at consequat. Vivamus eu pretium nunc. Donec ut magna id tellus consequat aliquam non in lacus. Aenean maximus commodo blandit. Phasellus viverra tincidunt felis ut molestie.
            </p>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium est nec sapien tempus, sed placerat ex suscipit. Etiam convallis ac erat at consequat. Vivamus eu pretium nunc. Donec ut magna id tellus consequat aliquam non in lacus. Aenean maximus commodo blandit. Phasellus viverra tincidunt felis ut molestie.
            </p>
          </div>
          <div className='md:w-1/2 flex justify-end w-full   mt-4 md:mt-0 '>  <img src={visionImg} className='w-[400px] md:max-w-lg shadow-lg rounded-lg' alt='vision'/>
          </div>

        </div>
      
    </div>
  )
}

export default Vision
