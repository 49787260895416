
import Routing from './Routing';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import About from './pages/About';
import Products from './pages/Products';

import { useEffect, useRef } from 'react';
import{useLocation } from "react-router-dom"
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Gallery from './components/Gallery/Gallery';

//tawk-to live chat

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }


  return (
    <>
     
    <Routes>

      <Route exact path='/' element={<Routing/>}>
        <Route index path='/' element={<Home/>  } />
        <Route index path='/contact-us' element={<ContactUs/>} />   
        <Route index path ='/services/:id' element={<Services/>}/> 
        <Route index path='/about-us' element={<About/>}/>
        <Route index path='/products/:id' element={<Products/>}/>
        <Route index path='/gallery' element={<Gallery/>}/>
        
      </Route>

    </Routes>
    
    <TawkMessengerReact
      propertyId='65c8c4f40ff6374032cbc00d'
      widgetId='1hmc42n5a'
      />
    
    </>
  );
}

export default App;
