import React from 'react'
import ProductCard from './ProductCard'
import cylinderlinear  from '../../assets/images/MainEngineSpares/LineraCylinder.jpg'
import cylinderhead  from '../../assets/images/MainEngineSpares/cylinderhead.jpg'
import piston  from '../../assets/images/MainEngineSpares/piston.png'
import exhaustvalve from "../../assets/images/MainEngineSpares/engineacsessoriesandtools.webp"
import pistonrings  from '../../assets/images/MainEngineSpares/pistonrings.jpg'
import Connectingrods  from '../../assets/images/MainEngineSpares/pistonandconnectingrod.jpg'
import bearings  from '../../assets/images/MainEngineSpares/bearings.jpg'
import thurstbearings  from '../../assets/images/MainEngineSpares/thrust bearing.jpg'
import crossbearings  from '../../assets/images/MainEngineSpares/crossheadbearing2.png'
import maccessories from '../../assets/images/MainEngineSpares/engineacsessoriesandtools.webp'
import turbo  from '../../assets/images/MainEngineSpares/Turbocharger.jpg'
import fuelinjections  from '../../assets/images/MainEngineSpares/fuelinjector.jpg'
import fuelpumps  from '../../assets/images/MainEngineSpares/fuelpump.jpg'

const MEngineSpares = () => {
    const EngineSpareParts=[
        {
            "name":"Cylinder Linear",
            "image":cylinderlinear
        },
        {
            "name":"Cylinder Head",
            "image":cylinderhead
        },
        {
            "name":"Piston",
            "image":piston
        },
        {
            "name":"Exhaust Vavle",
            "image":exhaustvalve
        },
        {
            "name":"Piston Rings",
            "image":pistonrings
        },
        {
            "name":"Connecting Rods",
            "image":Connectingrods
        },
        {
            "name":"Thrust Bearings",
            "image":thurstbearings
        },
        {
            "name":"Main Bearings",
            "image":bearings
        },
        {
            "name":"Cross Head Bearings",
            "image":crossbearings
        },
        {
            "name":"Main Engine Accessories and Tools",
            "image":maccessories
        },
        {
            "name":"Turbochargers Linear",
            "image":turbo
        },
        {
            "name":"Fuel Injection Equipments",
            "image":fuelinjections
        },
        {
            "name":"Fuel Pumps Fuel valves ",
            "image":fuelpumps
        }
    ]
  return (
    <div className='font-poppins'>
        
        {/* sub heading */}
        <div className='bg-gray-300 md:text-xl text-center py-6'>(All major and minor spares of <span className='font-medium'>MAN , b&w , sulzer, wartsila, mitsubishi, pilestick, fiat,mak, heinshin</span> etc)</div>
        {/* products */}
        <div className='bg-white flex items-center justify-center'>
        <div className='grid  grid-cols-2 xl:grid-cols-4 max-w-6xl gap-4 py-8' >

            {EngineSpareParts.map((product,index)=>{
                return(
                    <ProductCard
                    index={index}
                    name={product.name}
                    image={product.image}
                    />
                    
                )
            })}
            
        </div>
        </div>
      
    </div>
  )
}

export default MEngineSpares
