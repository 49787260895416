import React from 'react'
import Connect from '../components/Contact/Connect'
import Contactus from '../components/Home/Contactus'
import HeroSection from '../components/Services/HeroSection'
import ServiceInfo from '../components/Services/ServiceInfo'

const Services = () => {
  return (
    <div>
        <HeroSection/>
        
        <ServiceInfo/>
      <Connect/>
      <Contactus/>

    </div>
  )
}

export default Services
