import React from 'react'
import { Outlet } from 'react-router-dom'
import MaxWidthWrapper from './components/MaxWidthWrapper'
import NavBar from './global/NavBar'
import Footer from './global/Footer'



const Routing = () => {
    return (
    <>
    
        <MaxWidthWrapper>
            <NavBar/>
            
            
            <Outlet />
            <Footer/>
        </MaxWidthWrapper>
        </>
    )
}

export default Routing