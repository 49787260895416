import React from 'react';

const GoogleMap = () => {
  return (
    <div className='p-8  md:p-28 font-poppins bg-white'>
      <div
      className='pb-4 text-2xl md:text-5xl font-bold   tracking-wide  text-center text-primaryClr ' >Get directions in</div>
      <p className='text-center text-sm md:text-base pb-4 text-primaryClr'>Building C1, first floor. working hours Monday to Thursday from 8 to 5pm. Friday from 8 to 1pm. Saturday 8 to 3pm.</p>
    <div className="relative overflow-hidden" style={{ paddingBottom: "56.25%" }}>
      <iframe 
        className="absolute inset-0 w-full h-full" 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.572962670915!2d55.45008707524257!3d25.419101277568707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5784a7c97153%3A0x2f7b6f7ff376bcb3!2sAjman%20Free%20Zone!5e0!3m2!1sen!2s!4v1722273860327!5m2!1sen!2s" 
        allowfullscreen="" 
        loading="lazy"
      ></iframe>
    </div>
    </div>
  );
};

export default GoogleMap;