import React from 'react'

const MaxWidthWrapper = ({ children }) => {
    
    return (
        <div className={`  bg-primaryClr min-h-screen  `}>
            <div className={" mx-auto    w-full  "}>
                {children}
            </div>
        </div>
    )
}

export default MaxWidthWrapper
