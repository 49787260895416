import React from 'react'
import Contactus from '../components/Home/Contactus'
import Connect from '../components/Contact/Connect'
import Herosection from '../components/Contact/HeroSection'
import GoogleMap from '../components/Contact/GoogleMaps'

const ContactUs = () => {
  return (
    <div>
        <Herosection/>
        <Connect/>
        <GoogleMap/>
      <Contactus/>
    </div>
  )
}

export default ContactUs
