import React from 'react'
import { useInView } from "react-intersection-observer";
import { fadeIn } from '../../variants';
import { motion } from "framer-motion";
const HeroSection = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
      });
  return (
    <div
    
     className=" bg-img4  bg-center font-poppins    bg-cover h-[40vh] md:h-[60vh] pt-28">
      <div className=' flex text-secondaryClr items-end justify-center gap-1 pt-8  h-full w-full'>
        <h1 className='font-bold xl:text-5xl text-center bg-primaryClr/60 w-full backdrop-blur-[2px] p-8 shadow-lg rounded-md text-white drop-shadow-lg'>Fortune Marine</h1>
      
      </div>
    </div>
  )
}

export default HeroSection
