import React from 'react'
import getInTouch from '../../assets/images/Services/connect.webp'
import { IoIosCall } from 'react-icons/io'
import { IoLocationSharp } from 'react-icons/io5'
import { CiMail } from 'react-icons/ci'

const Contactus = () => {
    return (
        <div className='bg-primaryClr  text-sm md:text-base font-poppins'>
            <div className='flex flex-col items-center justify-center  py-16'>
                <div className='text-3xl md:text-5xl text-center  text-white font-medium'>
                    Get in Touch with Us
                </div>
                <p className='pt-2 text-white'>
                    See our contact page for more information
                </p>
                <div className='flex flex-col md:flex-row items-center justify-evenly w-full pt-16 px-4 lg:px-24'>
                   
                    <div className=''>
                        <img
                            src={getInTouch}
                            className=' max-w-xs md:max-w-sm lg:max-w-xl shadow-2xl shadow-slate-400/40 rounded-full'
                        />
                    </div>
                    <div className=' flex flex-col gap-4 items-start pl-0 pt-4 md:pt-0 md:pl-6'>
                        <div className='flex group cursor-pointer  items-center justify-center text-white gap-4'>
                            <IoIosCall className='text-7xl group-hover:text-secondaryClr ' />
                            <p className='group-hover:text-secondaryClr  transition-all ease-in-out duration-500 group-hover:scale-125 '>090078601</p>
                        </div>
                        <div className='flex group cursor-pointer  items-center justify-center text-white gap-4'>
                            <IoLocationSharp className='text-7xl group-hover:text-secondaryClr ' />
                            <p className='group-hover:text-secondaryClr  transition-all ease-in-out duration-500 group-hover:scale-110 '>Ajman Free Zone Building C1, first floor.</p>
                        </div>
                        <div className='flex group cursor-pointer items-center justify-center text-white gap-4'>
                            <CiMail className='text-7xl group-hover:text-secondaryClr  ' />
                            <p className='group-hover:text-secondaryClr  transition-all ease-in-out duration-500 group-hover:scale-110 '>info@fortunemarineco.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus
