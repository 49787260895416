import React from 'react'
import processImg from '../../assets/images/AboutUS/aboutus2.webp'

const Process = () => {
  return (
    <div className='bg-slate-200'>
        <div className='flex flex-col-reverse md:flex-row items-center justify-between px-4 md:px-20 py-20'>
          <div className='md:w-1/2 flex items-end w-full mt-4 md:mt-0 '>  <img src={processImg} className='w-[350px] md:max-w-lg shadow-lg rounded-lg' alt='vision'/>
          </div>
          <div className=' flex flex-col md:w-1/2 gap-4'>
            <h1 className='font-bold text-3xl md:text-5xl text-primaryClr text-center md:text-start'>Our Process</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium est nec sapien tempus, sed placerat ex suscipit. Etiam convallis ac erat at consequat. Vivamus eu pretium nunc. Donec ut magna id tellus consequat aliquam non in lacus. Aenean maximus commodo blandit. Phasellus viverra tincidunt felis ut molestie.
            </p>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium est nec sapien tempus, sed placerat ex suscipit. Etiam convallis ac erat at consequat. Vivamus eu pretium nunc. Donec ut magna id tellus consequat aliquam non in lacus. Aenean maximus commodo blandit. Phasellus viverra tincidunt felis ut molestie.
            </p>
          </div>

        </div>
      
    </div>
  )
}

export default Process
