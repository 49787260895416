import React from 'react'
import { useInView } from 'react-intersection-observer'
import { fadeIn } from '../../variants'
import { motion } from 'framer-motion'
import aboutUS from '../../assets/images/AboutUS/About2.png'

const AboutUs = () => {
    // const { ref } = useInView({ threshold: 0.5, triggerOnce: false });
    return (
        <div className='bg-slate-200 roundedcustom -mt-12 pt-8 pb-10 shadow-2xl h-fit font-poppins'>
            <div
                
                className='flex flex-col items-center  justify-center'
            >
                <h1
                variants={fadeIn('down', 1.5)}
                 className='text-3xl md:text-5xl lg:text-7xl  pt-10 pb-10 md:pb-20 tracking-wider leading-3 font-medium text-primaryClr'>
                    About Us
                </h1>
                <div className='flex  text-start text-sm md:text-base md:pt-0 flex-col-reverse md:flex-row items-center md:gap-2  justify-center px-4 md:px-24'>
                    <div className=''>
                        <p variants={fadeIn('down', 1.5)}>
                            Frontline Marine Services is a company based in
                            Dubai that deals with Equipment Repairing &
                            Maintenance. It has grown to become a prominent
                            participant in the ship repair, diesel engine
                            overhauling, spare services to marine and oil
                            industry since years. The Company is supported by
                            professionally qualified and experienced technical
                            and commercial staff. Our industrial knowledge and
                            urge to strive for perfection and timely delivery
                            has always been valued respected and appreciated and
                            today our clients are satisfied. Our services
                            Includes installing and repairing the equipment
                            related to structures for marine transportation,
                            such as ships, boats, floating structures, pontoons,
                            barges, cranes, the concerned equipment include
                            propulsion systems, steering systems, engines
                            overhauling, generators, ventilation blowers,
                            boilers, spindles, pipes, communication and
                            measuring devices , Blasting & Painting etc.
                        </p>
                    </div>
                    <div 
                    variants={fadeIn('down', 1.5)}
                    className=' flex justify-end pb-10 md:pb-0 '>
                        <img src={aboutUS} className=' rounded-md xl:max-w-lg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
