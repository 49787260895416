import React from 'react'
    
import emergencyGenerator  from '../../assets/images/DeckMachinery/emergencygenerator.webp'
import crane  from '../../assets/images/DeckMachinery/crane.webp'
import chains  from '../../assets/images/DeckMachinery/chains.jpg'
import fenders  from '../../assets/images/DeckMachinery/fennders.jpg'
import windlasswrich  from '../../assets/images/DeckMachinery/windalsswrich.jpg'
import horns  from '../../assets/images/DeckMachinery/shiphorns.webp'
import safetyequipment  from '../../assets/images/DeckMachinery/safetyequipments.webp'
import hatchcover  from '../../assets/images/DeckMachinery/hatchcoverparts.jpg'

import fireFighting  from '../../assets/images/DeckMachinery/firefightingequipment.jpg'
import ProductCard from './ProductCard'


const DeckMachinery = () => {
    const EngineSpareParts=[
        {
            "name":"Anchor and anchor chains",
            "image":chains
        },
        {
            "name":"Hydarulic and electric crane and crane accessories",
            "image":crane
        },
        {
            "name":"Windlass and mooring winch",
            "image":windlasswrich
        },
        {
            "name":"Emergency Generator",
            "image":emergencyGenerator
        },
        {
            "name":"Fire Fighting equipment",
            "image":fireFighting
        },
        {
            "name":"Lifeboats, floating blocks, davits, and life saving equipments",
            "image":safetyequipment
        },
        {
            "name":"Electric and ship horns",
            "image":horns
        },
        {
            "name":"Fenders (Rubber and foam filled) as per client requirements",
            "image":fenders
        },
        {
            "name":"Hatch cover parts and hydraulic rams",
            "image":hatchcover
        },
       
    ]
  return (
    <div className='font-poppins'>
        
    {/* sub heading */}
    <div className='bg-gray-300 md:text-xl text-center py-6'>(All major and minor spares of <span className='font-medium'>MAN , b&w , sulzer, yanmar , mitsubishi, pilestick, daihatsu fiat , mirrlees , blackstone</span> etc)</div>
    {/* products */}
    <div className='bg-white flex items-center justify-center'>
    <div className='grid  grid-cols-2 xl:grid-cols-4 max-w-6xl gap-4 py-8' >

        {EngineSpareParts.map((product,index)=>{
            return(
                
                <ProductCard
                index={index}
                name={product.name}
                image={product.image}
                />
                
            )
        })}
        
    </div>
    </div>
  
</div>
  )
}

export default DeckMachinery
