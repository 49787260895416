import React from 'react'
import { useInView } from "react-intersection-observer";
import { fadeIn } from '../../variants';
import { motion } from "framer-motion";
const HeroSection = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
      });
  return (
    <motion.div
    ref={ref}
    initial="hidden"
    animate={inView ? "show" : "hidden"}
    viewport={{ once: true }}
    variants={{
      hidden: {},
      show: {
        transition: {
          staggerChildren: 0.15,
        },
      },
    }}
     className=" bg-hero6  bg-center lg:bg-top  bg-cover h-[80vh] md:h-screen pt-28">
      <div className=' flex text-secondaryClr flex-col gap-1 items-center justify-center pt-8'>
        <motion.h1 variants={fadeIn('down', 1.5)} className='uppercase italic drop-shadow-2xl backdrop-blur-[1px] shadow-xl border p-4 border-secondaryClr/30 rounded-md text-4xl md:text-[80px] leading-tight  font-semibold font-poriet'>Fortune marines</motion.h1>
        <motion.p   variants={fadeIn('up', 1.5)}  className='italic text-lg '>Built for the sea</motion.p>

      </div>
    </motion.div>
  )
}

export default HeroSection
