import React from 'react'
import ProductCard from './ProductCard'
import frame  from '../../assets/images/Aucengine/auxengineframe.jpg'
import bedplate  from '../../assets/images/Aucengine/auxbedplate.jpg'
import Crankshaft  from '../../assets/images/Aucengine/auxcamshaft.jpg'
import Cylinderlinears  from '../../assets/images/Aucengine/auxcylinderlinears.jpg'
import Cylinderheads  from '../../assets/images/Aucengine/aux cylinderheads.jpg'
import Pistons  from '../../assets/images/Aucengine/auxpiston.webp'
import Pistonsrings  from '../../assets/images/Aucengine/auxpistonrings.jpg'
import Governor  from '../../assets/images/Aucengine/auxgovernor.jpg'
import Aircoolers  from '../../assets/images/Aucengine/auxgovernor.jpg'
import Turbochargers  from '../../assets/images/Aucengine/auxturbochargers.jpg'
import IntermediateGears  from '../../assets/images/Aucengine/auxgears.png'
import fuelpumps  from '../../assets/images/MainEngineSpares/fuelpump.jpg'
const AEngineSpare = () => {
    const EngineSpareParts=[
        {
            "name":"Frame",
            "image":frame
        },
        {
            "name":"Bedplate",
            "image":bedplate
        },
        {
            "name":"Crankshaft",
            "image":Crankshaft
        },
        {
            "name":"Cylinder linears",
            "image":Cylinderlinears
        },
        {
            "name":"Cylinder Heads",
            "image":Cylinderheads
        },
        {
            "name":"Pistons",
            "image":Pistons
        },
        {
            "name":"Piston Rings",
            "image":Pistonsrings
        },
        {
            "name":"Governor",
            "image":Governor
        },
        {
            "name":"Aircoolers",
            "image":Aircoolers
        },
        {
            "name":"Turbochargers",
            "image":Turbochargers
        },
        {
            "name":"Intermediate Gears",
            "image":IntermediateGears
        },
        
        {
            "name":"Fuel Pumps Fuel valves ",
            "image":fuelpumps
        }
    ]
  return (
    <div className='font-poppins'>
        
    {/* sub heading */}
    <div className='bg-gray-300 md:text-xl text-center py-6'>(All major and minor spares of <span className='font-medium'>MAN , b&w , sulzer, yanmar , mitsubishi, pilestick, daihatsu fiat , mirrlees , blackstone</span> etc)</div>
    {/* products */}
    <div className='bg-white flex items-center justify-center'>
    <div className='grid  grid-cols-2 xl:grid-cols-4 max-w-6xl gap-4 py-8' >

        {EngineSpareParts.map((product,index)=>{
            return(
                <ProductCard
                index={index}
                name={product.name}
                image={product.image}
                />
                
            )
        })}
        
    </div>
    </div>
  
</div>
  )
}

export default AEngineSpare
