import React,{useState} from 'react'
import img1 from '../../assets/images/Gallery/pexels-tiger-lily-4483608.webp'
import img2 from '../../assets/images/Gallery/pexels-tiger-lily-4483609.webp'
import img3 from '../../assets/images/Gallery/pexels-tiger-lily-4483772.webp'
import img4 from '../../assets/images/Gallery/pexels-tiger-lily-4484078.webp'
import img5 from '../../assets/images/Gallery/pexels-tiger-lily-4484155.webp'
import img6 from '../../assets/images/Gallery/pexels-tiger-lily-4487362.webp'
import HeroSection from './HeroSection'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Gallery = () => {
    const [open,setOpen]= useState(false);
    const slides =[{ src: img1 },
        
        { src: img2},
        { src: img3 },
        { src: img4 },
        { src: img5},
        { src: img6 },
        

    ]
  return (
    <div className='pb-8 bg-white '>
        <div className='w-full'>
            <HeroSection/>
        </div>
        <div className='md-1:px-32 flex-col justify-center items-center'>
          <p className='text-center font-semibold tracking-wide text-xl font-poriet py-6'>Fulfil your onshore and offshore requirements with a call by partnering with <span className='font-bold text-2xl text-primaryClr'> Fortune Marine</span></p>
        <div className='flex-col justify-center items-center w-full'>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img1} alt='?'/>
        </div>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img2} alt='?'/>
        </div>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img3} alt='?'/>
        </div>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img4} alt='?'/>
        </div>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img5} alt='?'/>
        </div>
        <div onClick={()=>{setOpen(true)}} className='cursor-zoom-in' >
            <img className='rounded-md' src={img6} alt='?'/>
        </div>
        </div>
     
     {
             <Lightbox
                 open={open}
                 close={() => setOpen(false)}
                 slides={slides}
            />
     }  
        </div>
   
    </div>
  )
}

export default Gallery