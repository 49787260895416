import React from 'react'
import { FaPeopleGroup } from "react-icons/fa6";
import { FaHeadset } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
const OnlineTestCard = ({ heading, icon, para }) => {
    return (
        <div className='relative '>
            <div className='grid py-8 xl:py-0 xl:min-h-[70vh] cursor-pointer  w-full place-items-center hover:scale-105 transition-all ease-in-out duration-300 '>
                <div className='relative h-96 w-80'>
                    <div className='absolute left-1/2 top-1/2 h-96 w-80 -translate-x-1/2 -translate-y-1/2 rotate-6 rounded-2xl bg-gray-400'></div>
                    <div className='absolute left-1/2 top-1/2 h-96 w-80 -translate-x-1/2 -translate-y-1/2 rotate-6 space-y-6 rounded-2xl bg-gray-100 p-6 transition duration-300 hover:rotate-0'>
                        <div className='flex justify-end'>
                            <div className='h-4 w-4 rounded-full bg-gray-900'></div>
                        </div>
                        <header className='text-center text-xl font-extrabold text-gray-600'>
                        {heading}
                        </header>
                        <div>
                            <p className='text-center flex items-center justify-center text-5xl font-extrabold text-gray-900 py-2'>
                                {icon}
                            </p>
                            <p className='text-center text-sm tracking-tight text-primaryClr'>
                                {para}
                            </p>
                        </div>
                        <footer className='mb-10 flex justify-center'>
                            {/* <button className='flex items-baseline gap-2 rounded-lg bg-[#FE5401] px-4 py-2.5 text-xl font-bold text-white hover:bg-[#FF7308]'>
                                <span>Start</span>
                                <i className='fas fa-hand-peace text-xl'></i>
                            </button> */}
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WhyUs = () => {
    return (
        <div className='bg-primaryClr  font-poppins'>
            <div className='flex flex-col items-center justify-center  py-24'>
                <h1 className='text-4xl lg:text-5xl  text-white font-medium'>
                    Why choose us ?
                </h1>
                <p className='max-w-2xl lg:max-w-3xl  text-center pt-4 text-white text-xs lg:text-sm   '>
                    Fortune marines company and its management are fully committed to
                    ensuring the health and safety of all clients and staff,
                    contracted personnel and any other persons who are directly
                    or indirectly linked with its business and operations
                </p>
                <div className='relative grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pt-8  xl:pt-0 xlpb-0 gap-2 md:gap-12 '>
                    <OnlineTestCard
                        heading='Skilled staff'
                        icon={<FaPeopleGroup className='text-7xl text-primaryClr'/>}
                        para='Our team consists of highly trained professionals with years of experience in the industry. Each member is dedicated to providing exceptional service and expertise in their respective fields, ensuring that your needs are met with the utmost precision and care.'
                    />
                    <OnlineTestCard
                        heading='24/7 solutions'
                        icon={<FaHeadset className='text-7xl text-primaryClr'/>}
                        para='We provide accurate and timely solutions.We recognize that every client has unique requirements. Our team works closely with you to understand your specific needs and provide customized solutions that best fit your operational demands.'
                    />
                    <OnlineTestCard
                        heading='Quality Assurance'
                        icon={<MdVerified className='text-7xl text-primaryClr'/>}
                        para='We are committed to delivering the highest quality parts and services. Our rigorous quality control processes ensure that every product we supply meets stringent standards, providing you with reliable and durable solutions for your marine needs.'
                    />
                </div>
            </div>
        </div>
    )
}

export default WhyUs
