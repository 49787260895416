import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Toaster } from 'react-hot-toast';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <HashRouter>
    
    <App />
    <Toaster
           toastOptions={{
            className: '',
            style: {
              background: 'rgba(20, 48, 43, 0.6)',  // Glassy effect background color
              color: '#A6ADBB',
              backdropFilter: 'blur(10px)',  // Apply blur effect
              border: '1px solid rgba(255, 255, 255, 0.1)',  // Optional: light border for better visibility
              borderRadius: '10px'  // Optional: rounded corners for better appearance
          }
           }}
            />
  </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
